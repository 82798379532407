.home {
    overflow: auto;
    width: 100%;
    background: #000;
}

.slick-next {
    right: 25px !important;
    display: none !important;
}

.layout {
    width: 100%;
}

.home__banner1 {
    background-image: url('../Images/gear.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 60px 20px;
    font-family: 'Irish Grover';
}

.home__banner1-content {
    width: 70%;
    margin: auto;

}

.home__banner1-content-ul {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.home__banner1-content h1 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1rem 0;
    animation: animateL2R 1s linear alternate;
}

.home__banner1-content li {
    font-size: 1.2rem;
    /* margin-top: 2rem; */
    padding: .5rem 2.2rem;
    animation: animateR2L 1s linear alternate;
}

.home__banner1-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: auto;

}

.home__banner1-bottom h1 {
    font-weight: bold;
    font-size: 1.5rem;

}

.eyeimg {
    width: 10rem;
}

.home__banner2 {
    background: rgb(227, 242, 246);
    background: linear-gradient(180deg, rgba(227, 242, 246, 1) 4%, rgba(21, 170, 187, 0.9248074229691877) 100%);
    width: 100%;
}

.vision {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 70%;
    margin: auto;
}

.mission {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: auto;
    padding: 2rem 0;
}

.home__banner2-img {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bulbimg,
.stairsimg {
    width: 33%;
}

.vision h2,
.mission h2 {
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Alatsi';
    padding: 1rem;
    animation: animateL2R 0.5s linear alternate;
}

.vision p,
.mission p {
    font-size: 1.2rem;
    animation: animateR2L 0.5s linear alternate;
    padding: 1rem 2rem;
    text-align: justify;
}

@keyframes animateL2R {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(10%);
    }
}

@keyframes animateR2L {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-10%);
    }
}

.home__banner3 {
    background: #594d4d;
    padding: 2.6rem 0;
    gap: 3rem;
    color: black;
    box-shadow: 5px 5px 1rem rgba(0, 0, 0, 0.5);
}

.home__banner3-content {
    color: #fff;
    height: 100%;
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.2rem;
    border-top: 1px solid gray;

}

.home__banner3-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 70%;
    margin: auto;
    background: #000;
}

.home__banner3-logo {
    font-size: 2rem;
    width: 70%;
    text-align: center;
    margin: auto;

}

.home__banner3-logo h1 {
    font-size: 2rem;

}

.home__banner3-content h2 {
    font-size: 1.5rem;

}

.home__banner3-content p,
ul {
    padding: 1rem 0;
}

.home__banner3-content-ul {
    list-style: none;
    color: #fff;
    display: flex;


}

.icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fbicon,
.whatsappicon,
.instaicon,
.linkedinicon {
    border-radius: 5px;
    margin: 1rem;
}

.fbicon {
    background: #1877F2;
}

.whatsappicon {
    background: #13e725;
}

.instaicon {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}

.linkedinicon {
    background: #0077B5;
}

.ul__link {
    color: white;
}

/* Animations */
.home__banner1-content h1,
.home__banner1-content p,
.home__banner1-bottom h1,
.home__banner2-content p,
.home__banner2-img h2 {
    opacity: 0;
    animation: textReveal 1s forwards;
}

.home__banner3-content-li {
    margin-top: .5rem;
}

.home__banner3-content-li span:hover {
    color: #f7f7f7;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.5s;
    background: #000;
    box-shadow: 5px 5px 1rem rgba(0, 0, 0, 0.5);
    transform: rotate(25deg);
    /* Rotate by 25 degrees */
    padding: .2rem;
    border-radius: .2rem;
}

@keyframes textReveal {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Scroll reveal */
.reveal {
    opacity: 1;
    transform: translateY(0);
}

.home__banner3-content-li {
    font-size: 1rem;
}


.Missionimg {
    width: 40%;
}

.mission-content {
    width: 100%;
}


.footer__container {
    width: 100%;
    background-color: #333;
}

.footer {
    padding-bottom: 2rem;
    font-size: 1.4rem;
    max-width:1200px;
   
    margin: auto;

}

.footer__row {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 4rem;
    color: white;
}

.footer__list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

}

.footer__link {
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;


}



.footer__logo--box {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;

}


.footer__logo{
    width: 10rem;

}

.footer__navigation {
    border-top: 0.1rem solid #777;
    /* Changed to rem */
    padding-top: 2rem;

}

.footer__copyright {
    border-top: 0.1rem solid #777;
    /* Changed to rem */
    padding-top: 2rem;
}


.footer__list {
    list-style: none;
    color: white;
    flex-wrap: wrap;
}

.linkedinicon {
    color: #1DA1F2;
}

.footer__link:link,
.footer__link:visited {
    color: #f7f7f7;
    transition: all 0.5s;
}

.footer__link:hover,
.footer__link:active {
    background: var(--color-bg-home-horizontal);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.4);
    transform: rotate(3deg) scale(1.3);
}

.footer__copyright {
    border-top: 1px solid #777;
    text-align: center;
}
/* Responsive Design Placeholders */
@media (max-width: 480px) {

    /* Add responsive design changes here */
    .vision,
    .mission {
        margin: 0;
        width: 100%;

    }

    .vision h2,
    .mission h2 {
        font-size: 1.5rem;

    }

    .vision p,
    .mission p {
        font-size: 1.2rem;

    }
    .footer__row {
     gap: 0rem;
    }
    .footer__logo--box {
        margin-bottom: 1rem;
    }
    .footer__navigation {
        padding-top: 2rem;
    }
}

@media (max-width: 768px) {

    /* Add responsive design changes here */
    .vision,
    .mission {
        margin: 0;
        width: 100%;

    }

    .vision h2,
    .mission h2 {
        font-size: 1.2rem;

    }

    .vision p,
    .mission p {
        font-size: 1rem;


    }

    .home__banner1 {
        padding: 0;
    }

    .home__banner1-content h1 {
        font-size: 1.1rem;
        margin: 0;
    }

    .home__banner1-content li {
        font-size: 1rem;
        padding: 0;
    }

    .home__banner3-content h2 {
        font-size: 1.2rem;

    }

    .home__banner3-content p {
        font-size: 1rem;
    }

    .home__banner3 {
        flex-direction: column;
    }

    .home__banner3-content {
        width: 100%
    }

    .home__banner3-content h1 {
        font-size: 1.6rem;
    }

    .home__banner1-bottom h1 {

        font-size: 1rem;
        padding: .5rem;

    }

    .eyeimg {
        width: 8rem;
        padding: .5rem;
    }

    .home__banner3-content-li {
        font-size: 1rem;
    }
    .footer__row{
        flex-direction: column;
        margin: 0;
        width: 100%;
    }
    .col-1-of-2{
        width: 100%
    }
    .footer__row {
       gap: 0rem!important;
    }
    .footer__logo{
        width: 5rem;
    
    }
    .footer__logo--box {
        margin-bottom: 0px!important;
    }
    .footer__navigation {
        padding-top: 0rem!important;
    }
    .footer__items a{
     font-size: 1rem;
    }
    .footer__copyright a{
      font-size: .8rem;
    }
    .footer__copyright p{
        font-size: .8rem;
      }
      .footer__copyright{
        padding-bottom: 4rem;
      }
   
}

@media (max-width: 834px) {
    /* Add responsive design changes here */

    .vision {
        flex-direction: column;
    }

    .mission {
        flex-direction: column-reverse;
    }

    .bulbimg {
        width: 16%;
    }

    .Missionimg {
        width: 40%;
    }
}

@media (max-width: 1024px) {

    /* Add responsive design changes here */
    .vision {
        flex-direction: column;
        align-items: center;

    }

    .mission {
        flex-direction: column-reverse;
    }

    .bulbimg {
        width: 16%;
    }

    .Missionimg {
        width: 40%;
    }

}

