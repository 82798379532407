.whyby {
    width: 100%;
}

.banner {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-image: url('../Images/robot.png');
    background-repeat: no-repeat;
    background-size: cover, contain;
    background-position: center, top left;
    flex-direction: column;
    gap: 1rem;
    padding: 8rem 0;
}

.banner__h1 {
    font-size: 5rem;
    font-weight: bold;
    padding-top: 1rem;
}

.buysol {
    padding-left: 10%;
    color: black;
}

.welcomes {
    padding-left: 24%;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(255, 255, 255);
}

.you {
    padding-left: 50%;
    color: black;
}

.contentl__container {
    width: 100%;
    background-image: url('../Images/wave.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom bottom right;
    padding: 1rem;
    text-align: center;
    padding: 1rem 0;

}

.content1 {
    max-width: 1200px;
    margin: auto;
}

.whyby__content p {
    font-size: 1.4rem;
    text-wrap: balance;
}

.contentl__container2 {
    position: relative;
    width: 100%;
    background-image: url('../Images/business-person-looking-finance-graphs 1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 1rem 0;
    text-align: justify;

}

.content2 {
    max-width: 1200px;
    margin: auto;
}

.custom-list {
    list-style-type: disc;
    margin-left: 20px;
    padding: 1rem;
    width: 100%;
    display: none;
}

.custom-list li {
    margin-bottom: 10px;
    line-height: 1.6;
    padding: 0px 10px;
    /* padding-right: 5px; */
}

.nested-list {
    list-style-type: circle;
    margin-left: 20px;
}

.content2__container {
    max-width: 1200px;
    margin: auto;
}

.content2 p {
    font-size: 1.4rem;
    text-wrap: balance;
    text-align: justify;
    padding: 2rem;
}

.content3 {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-image: url('../Images/bluebg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.content3__container {
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.image__link {
    width: 100%;
    text-align: center;
}

.image__link .growth {
    width: 80%;
}

.content3 h2 {
    font-size: 2.2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-wrap: balance;
    padding: 2rem;
}

.content3 p {
    font-size: 1.2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-wrap: balance;
    text-align: justify;
    padding: 4rem 20px;
}

.content4Image {
    position: relative;
    width: 100%;
    display: flex;
    padding: 20px 20px 20px;
    flex-direction: column;
    align-items: center;
    background-image: url('../Images/progress-development-innovation-improvment-concept.png');
    background-repeat: no-repeat;
    background-position: bottom ;
    background-size: cover;
    
}

.content4__container {
    max-width: 1200px;
    margin: auto;
    padding: 3rem 0;
}

.content4 p {
    font-size: 1.2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: justify;
}

.growth__title {
    text-align: center;
}

.content4 h2 {
    font-size: 2.2rem;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-wrap: balance;
    /* padding-top: 0rem; */
    position: absolute;
    top: 0px;
    left: 40%;
    padding: 5px;
}

.banner2 {
    position: relative;
    padding: 10vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #7BCAFF;
}

.banner2 h1 {
    font-size: 3rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-wrap: balance;
    padding: 0.5rem;
}

.start {
    font-weight: bolder;
}

.applybtn {
    font-weight: 600;
    border-radius: 100px;
    border: 2px solid black;
    padding: .5rem 1.5rem;
}

.myrobot {
    display: none;
    width: 200px;
    height: 200px;
    object-fit: cover;
}


@media(max-width:1400px) {

    .banner {
        position: relative;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        background-image: url('../Images/robot.png');
        background-repeat: no-repeat;
        background-size: cover contain;
        background-position: center, top left;
        flex-direction: column;
        gap: 1rem;
        padding: 9rem 0;
    }

    .banner__h1 {
        font-size: 4rem;
        font-weight: bold;
        padding-top: 1rem;
    }

    .you {
        padding-left: 36%;
        color: black;
    }
}



@media(max-width:1315px){
    .banner {
        position: relative;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        background-image: url('../Images/robot.png');
        background-repeat: no-repeat;
        background-size: cover contain;
        background-position: center, top left;
        flex-direction: column;
        gap: 1rem;
        padding: 4rem 0;
    }

    

}


@media(max-width:1000px){
    .banner {
        position: relative;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        background-image: url('../Images/robot.png');
        background-repeat: no-repeat;
        background-size: cover contain;
        background-position: center, top left;
        flex-direction: column;
        gap: 0rem;
        padding: 0rem 0;
    }

}



/* Responsive design for smaller screens */
@media (max-width: 768px) {


    .myrobot {
        display: block;
    }

    .banner__h1 {
        font-size: 3rem;
        font-weight: bold;
        padding-top: 1rem;
    }

    .banner {
        height: 77vh;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: #b700b2;
        background-image: url(../Images/Robot2.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom center;

    }

    .buysol {
        color: black;
        position: absolute;
        top: 0;
        left: 10%;
        padding: 0px;
    }

    .welcomes {
        position: absolute;
        top: 95px;
        padding: 0px;
        left: 24%;
    }


    .you {
        position: absolute;
        top: 190px;
        left: 35%;
        padding: 0px;
        color: black;
    }

    .whyby__content p,
    .content2 p,
    .content3 p,
    .content4 p {
        font-size: 1rem;
        padding: 1rem 1.5rem
    }

    .custom-list {
        display: block;
        list-style-type: disc;
        margin-left: 0px;
        padding: 0rem;
    }



    .paragraph {
        display: none;
    }

    .image__link img {
        width: 80%;
    }

    .content4 h2 {
        font-size: 2.2rem;
        padding-top: 3rem;
        left: 33%;
        top: -34px;
    }

    .contentl__container {
        display: flex;
        align-items: center;
        justify-content: center;
        background-position: bottom right;
    }
}

@media (max-width: 600px) {

    .banner {
        height: 65vh;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: #b700b2;
        background-image: url(../Images/Robot2.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom center;
        gap: 0;
    }

    .custom-list {
        margin-left: 0px;
    }

    .custom-list li {
        font-size: 16px;
        padding: 0px 10px;
    }
}

@media (max-width: 380px) {
    .banner__h1 {
        font-size: 2rem;
        font-weight: bold;
        padding-top: 1rem;
    }

    .banner {
        height: 50vh;
    }

    .contentl__container {
        padding: 2rem 0;
        background-position: center center right;
    }

    .buysol {
        padding-left: 0%;
    }

    .welcomes {
        padding-left: 0%;
    }

    .you {
        padding-left: 0%;
    }

    /* .banner {
        padding: 2rem;
    } */

    .banner__h1 {
        padding-top: 0rem;
    }

    .content1 p,
    .content2 p,
    .content3 p,
    .content4 p {
        font-size: 0.8rem;
    }

    .custom-list {
        margin-left: 5px;
    }

    .custom-list li {
        font-size: 13px;
        padding-right: 5px;
    }

    .contentl__container2 {
        padding: 1rem 0;
    }

    .content3 h2 {
        font-size: 1.6rem;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: bold;
        text-wrap: balance;
        padding: 1rem;
    }

    .content4__container {
        padding: 0rem 0;
    }

    .content4 h2 {
        font-size: 1.6rem;
        padding-top: 1rem;
    }


}