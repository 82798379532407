.product__div1 {
    background-image: url("../Images/AboutUs.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.product__div1--container {
    max-width: 1200px;
    margin: auto;
}

.product__heading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product__heading .logo {
    width: 50%;
}

.product__content {
    width: 50%;
    padding: 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}

.product__ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    list-style: none;
    padding-bottom: 2rem;
}

.product__p {
    text-wrap: wrap;
    line-height: 1.5;
    font-weight: 400;
    font-family: 'Pridi', serif;
    font-size: 20px;
}

.product__points li {
    margin-top: .7rem;
    font-family: 'Pridi', serif;
    font-size: 20px;
}

.product__h2 {
    font-weight: 600;
    font-size: 36px;
    font-family: 'Alatsi', sans-serif;
}

.product__div2 {
    background-image: url("../../Assets/Images/bluebg.png");
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
}

.div2__heading {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 1200px;
    margin: auto;
    padding-top: 2rem;
}

.div2__content {
    width: 50%;
    padding: 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}

.div2__heading .SMSimg {
    width: 50%;
}

.div2__h2 {
    font-weight: 600;
    font-size: 36px;
    font-family: 'Alatsi', sans-serif;
}

.div2__p {
    text-wrap: wrap;
    line-height: 1.5;
    font-weight: 400;
    font-size: 20px;
    font-family: 'Pridi', serif;
}

.div2__ul {
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    list-style: none;
    margin: auto;
    padding-bottom: 2rem;
}

.div2__points li {
    margin-top: .7rem;
    font-size: 20px;
    font-family: 'Pridi', serif;
}


@media (max-width: 1200px) {

    .product__div1 {
        background-size: cover;
        height: auto;
        padding: 2rem;
        display: flex;
    }

    .product__content {
        width: 90%;
        padding: 2rem;
    }

    .product__heading {
        font-size: 1.2em;
        flex-direction: column;
    }

    .product__p {
        font-size: 16px;
    }

    .product__points li {
        font-size: 16px;
    }

    .product__h2 {
        font-size: 22px;
    }

    .product__div2 {
        height: auto;
        padding: 2rem;
    }

    .div2__content {
        width: 90%;
        padding: 2rem;
    }

    .div2__heading {
        flex-direction: column-reverse;

    }

    .div2__h2 {
        font-size: 22px;
    }

    .div2__p {
        font-size: 16px;
    }

    .div2__points li {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .product__content {
        width: 96%;
        padding: 0;
    }

    .productimg {
        width: 80%;
    }

    .product__content {
        width: 90%;
        padding: 0;
    }

    .product__div2 {
        height: auto;
        padding: 0;
    }

    .div2__content {
        width: 100%;
        padding: 2rem;
    }

    .div2__points {
        padding: 2rem;
    }

    .product__div1 {
        padding: 1rem;
    }

    .product__ul {
        padding: 1rem
    }
}