.imgslider {
  width: 100%;
}

.imgslider__container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  padding: .5rem;



}

.imgslider__left {
  flex-basis: 70%;
  padding: 1rem;
  height: 95vh;
  border-radius: 5px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  background-color: var(--color-mode-gray-3);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: .5rem;
}

.imgslider__right {
  flex-basis: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;
  height: 95vh;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
  border-radius: 5px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  background-color: var(--color-mode-gray-3);
  color: var(--color-mode-text);
}

.imgthumbnails {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
}

.imgthumbnails__box {
  width: 80%;
  height: 14rem;
  padding: .1rem;
  border-radius: .2rem;
  border: 1px solid gray;
}

.imgthumbnails img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: .2rem;


}

.konvajs-content {
  height: 640px !important;
  width: 640px !important;
  border-radius: 5px;
  object-fit: cover !important;
  border: 1px solid var(--color-border);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  background-color: var(--color-mode-gray-3);
}

.konvajs-content canvas {
  height: 640px !important;
  width: 640px !important;

}

.annotation-list {
  width: 40%;
  height: 640px;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid var(--color-border);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  background-color: var(--color-mode-gray-3);
  position: relative;

}

.anotation__list_item li {
  border: 1px solid gray;
  color: var(--color-mode-text);
  margin: 1rem 0;
  border-radius: 5px;
  padding: .2rem;
}

.anotation__list_item li input {
  padding: .3rem;
  width: 80%;
}

.anotation__list_item li button {
  background: none;
  height: 100%;
  width: 20%;
  padding: .3rem;
  border: 1px solid var(--color-border);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);

}

.anotation__list_item li button:hover {
  background: #7857E0;
  color: white;


}

.dropzone {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-mode-text);
}

.imageCanva {
  position: relative;
  width: 80%;

}

.imageCanva__controls {
  position: absolute;
  /* top: 10px; */
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  background: red;
}

.imageCanva__controls button {
  background: none;
  color: gray;
}

.count {
  display: flex;
}

.imageCanva__btn {
  color: gray;
}

.imageCanva__btnback {
  color: #333;
}

.imageCanva__Clear {
  color: red;
}

.annotation__button {
  position: absolute;
  bottom: 10px;
  width: 50%;

}

.annotation-list input {
  padding: .5rem;
  border: 1px solid gray;
  width: 50%;
  border-radius: .2rem;
  color: var(--text-color);

}
.annotation-list input:focus {
 outline: none;
}
.annotation__button button {
  text-align: center;
  padding: .5rem;
  border: 1px solid gray;

}

.annotation_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  padding: .5rem 0;
  margin-bottom: 1rem;
  color: var(--color-mode-text);
}
.imguploderspinner {
  width: 40px;
  height: 40px;
  border: 4px solid #ccc;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}