@import '../Styles/theme-dark.css';
@import '../Styles/theme-light.css';


.addform {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .5rem;

}
.modal-title h4{
 margin: .5rem 0;
}
.add-table {
  padding: 1rem;
  background:var(--color-mode-gray-3)
}
.add-table h4 {
  padding: .5rem;
  border-bottom: 2px solid var(--color-mode-text);
  font-size: 20px;
  color: var(--color-mode-text);
  background:var(--color-mode-gray-3)
}

.modal-closebtn {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  padding: .4rem;
  color: var(--color-mode-text);


}

.modal-closebtn {
  border-radius: 100%;
  color: var(--color-mode-text);
  margin:0;
  background: none;

}

.Add_form__group {
  width: 100%;
}

.inputs {
  padding: .5rem;
  border: none;
  width: 100%;
  font-size: 16px;
  color: var(--color-mode-text);
  border-radius: .2rem;
  outline: none;
  box-shadow: 0 0rem .5rem rgba(0, 0, 0, 0.2);
  background:var(--color-mode-gray-3);
  border: 1px solid var(--color-mode-gray-5);

  
}

.inputs:focus {
  border: 1px solid var(--color-mode-text);
  outline: none;
  box-shadow: 0 0rem .5rem rgba(0, 0, 0, 0.5);
}


.inputs::-webkit-input-placeholder {
  color: #999;
}

.add__form-label {
  font-size: 1rem;
  font-weight: 600;
  margin-top: .3rem;
  display: block;
  transition: all .3s;
  padding-bottom:.3rem ;
  color: var(--color-mode-text);

}

.inputs:placeholder-shown+.add__form-label {
  opacity: 0;
  visibility: hidden;
}


.submit-btn {
  padding: .6rem 1.6rem;
  color: var(--color-mode-text);
  border: 2px solid var(--color-mode-text);
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  background: var(--color-mode-gray-3);

}

.submit-btn:hover {
  background: var(--color-mode-gray-3);

}