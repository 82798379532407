@import '../../Assets/Styles//theme-dark.css';
@import '../../Assets/Styles/theme-light.css';


.single-page {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 4rem;
  padding: 1rem 0;
  min-height: 100%;
}

@media (max-width: 468px) {
  .single-page {

    flex-direction: column
  }
}


.video-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-mode-secondary);
  border-radius: .5rem;

}

.configStream {
  position: relative;
  flex: 1;
  background-color: var(--color-mode-gray-3);
  border-radius: 5px;
  box-shadow: 3px, 3px  rgba(0, 0,0, 0.15);
  padding:.5rem;
  border: 1px solid rgba(255, 255, 255, 0.222);
}

/* @media(min-width: 400px){
  .configStream{
    flex: 1;
    flex-direction: column;
  }
} */

/* @media(min-width: 1024px){
  .configStream{
    width: 60%;
  }
} */

.configStream__btn {
  border: 1px solid var(--color-mode-text);
  margin: 1rem .3rem;
  padding: .5rem 1rem;
  color: var(--text-color);
  border-radius: .2rem;
}

.configStream__btn:hover {
  background: var(--color-mode-gray-1);
  color: var(--color-mode-text);
}

.configStream__btn:active {
  box-shadow: 0px 0px .2rem rgba(0, 0, 0.5);
}


.camera__btn:active {
  box-shadow: 0px 0px .2rem rgba(0, 0, 0.5);
  padding: .6rem 1rem;
}

.dateTime__checkbox {
  padding: .5rem;
}

.dateTime {
  display: flex;
  gap: 1rem;
  padding-top: 1rem;
}

.objeccount_butns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: .5rem 1rem;
}
.objeccount_butns button {
  padding: .5rem .5rem;
  border-radius: 5px;

}

.conditionalbox {
  width: 100%;
  display: flex;
  align-items: center;
  gap: .3rem;
}

.smsModal {
  padding: 1rem 2rem;
  flex: 1;
  color: var(--color-mode-text);
  background-color: var(--color-mode-gray-3);
  border: 1px solid rgba(255, 255, 255, 0.222);
  border-radius: 5px;
  box-shadow: 3px, 3px rgba(0, 0, 0, 0.15);
}

/* @media(min-width: 1024){
  .smsModal{
    width: 50%;
  }
} */

/* @media(min-width: 400px){
  .smsModal{
    flex: 1;
    flex-direction: column;
  }
} */

.GroupAlertSelection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  font-size: 16px;
  border: 1px solid var(--color-mode-text);
  padding: 1rem;
  margin: 1.3rem 0;
  border-radius: .5rem;
}


.alertmsg {
  font-size: 1rem;
  text-align: center;
  /* margin-bottom: 1rem; */
  font-family: inherit;
  color: var(--color-mode-text);
  font-weight: 400;
}

.alertTitle {
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: .4rem;
  color: var(--color-mode-text);

}

.userContact {
  padding-right: 1rem;
  color: var(--color-mode-text)
}

.userAlertSelection {
  display: flex;
  justify-content: center;
  align-items: self-start;
  flex-direction: column;
  gap: 1rem;
  font-size: 16px;
  border: 1px solid var(--color-mode-text);
  padding: 1rem;
  border-radius: .5rem;
}

.selectedUser,
.selectedNotification,
.selectedServices__title {
  margin-bottom: 0.5rem;
}

.selectedServices__title {
  width: 50%;
}

.servicesInputBox {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;



}

.UserData {
  display: flex;
  align-items: self-start;
  flex-direction: column;
}

.UserData p {
  margin-bottom: 0.5rem;

}

.servicesInputBox input[type="checkbox"] {
  margin-right: 0.5rem;


}

.checkbox-label {
  color: #333;
  font-size: 1rem;
}

.closeBtn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.25rem 0.5rem;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  border: 1px solid var(--color-mode-text);
  border-radius: 50%;
}

.closeBtn:hover {
  background-color: var(--color-mode-gray-3);

}

.SubmitSchduler {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  gap: 1rem;
}

.SubmitSchdulerbtn {
  padding: .5rem 2.5rem;
  text-align: center;
  font-size: 16px;
  border: 1px solid var(--color-mode-text);
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: .2rem;

}

.SubmitSchdulerbtn:hover {
  background: var(--color-mode-text);
  color: var(--active-color);

}

.SubmitSchdulerbtn:active {
  background: var(--color-mode-text);
  box-shadow: 2px 2px 1rem rgba(0, 0, 0.5)
}

.selectUser {
  padding: .5rem 1rem !important;
  color: var(--text-color);
  border-radius: .2rem;
  border: 1px solid var(--color-mode-text);
  width: 100% !important;

}

.setGroup {
  margin-top: 3rem;
  padding: .5rem 1rem !important;
  border: 1px solid var(--color-mode-text);
  border-radius: .2rem;
}

.selectOption {
  margin-top: 1rem;
}

.selectBox p {
  font-size: 1rem;
  padding: 1rem 0;
  color: var(--color-mode-text);
}

.selectContainer {
  display: flex;
  align-items: center;

  margin-top: 3rem;
}

.conditionalinput {
  display: flex;
  align-items: center;
  gap: 1rem;

}

.numberOfPeopleToDetect {
  margin-top: .5rem;
  padding: .5rem 1rem;
  border: 1px solid var(--color-mode-text);
  color: var(--text-color);
  border-radius: .2rem;
  width: 100%;


}

.selectServicesContainer {
  display: flex;
  align-items: start;
  gap: 1rem;
  width: 100%;

}

.services__box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

@media(max-width: 1024px) {
  .single-page {
    flex-direction: column;
  }

  .numberOfPeopleToDetect {
    width: 8rem;
  }
}

@media(max-width: 900px) {
  .single-page {
    flex-direction: column;
  }
}

@media(max-width: 600px) {
  .dateTime {
    flex-direction: column;
  }
}

@media(max-width: 768px) {
  .single-page {
    flex-direction: column;
  }

  .numberOfPeopleToDetect {
    width: 100%;
  }

  .conditionalbox {
    flex-direction: column;
    align-items: start;
  }

  .dateTime {
    display: flex;
  }


}

@media (max-width: 468px) {
  .selectServicesContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .endtimeLabel {
    display: inline-block;
  }

  .dateTime {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .services__box {
    justify-content: flex-start;

  }

  .smsModal {
    padding: 1rem;

  }

  .dateTime {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .conditionalinput {
    display: flex;
    /* flex-direction: column; */
  }

  .numberOfPeopleToDetect {
    margin-top: .5rem;
    /* padding: 0.5rem .5rem; */
    border: 1px solid var(--color-mode-text);
    border-radius: .2rem;
    width: 100%;
  }

}

.groupContainer {
  display: flex;
  gap: 2rem;
  align-items: flex-start;


}

.groupColumn {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  border-right: 1px solid var(--color-mode-text);
  align-items: flex-start;

}

.groupContainer .groupColumn:last-child {
  border-right: none;
}

.servicesInputBox {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

/* src/MultiSelectDropdown.css */
.multi-select {
  display: flex;
  align-items: center;

}

.multi-select-dropdown {
  position: relative;
  width: 200px;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: var(--color-mode-gray-3);
  cursor: pointer;
  border: 1px solid var(--color-mode-text);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--color-mode-gray-3);
  border: 1px solid var(--color-mode-text);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;

}

.count-input {
  margin-left: 10px;
  width: 50px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: var(--color-mode-gray-3);
}

.dropdown-option {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: var(--color-mode-gray-3);
}

.dropdown-arrow {
  margin-left: 10px;
}