 .BottomNav{
   display: none;
 }
.bottomnavbar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .bottomnavbar__link {
    color: #666666;
    text-align: center;
    flex: 1;
    padding: 10px 0;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
    font-weight: bold;
  }
  
  .bottomnavbar__link svg {
    display: block;
    margin: 0 auto;
    font-size: 20px;
  }
  
  .bottomnavbar__link.active {
    background-image: linear-gradient(180deg, rgba(250,57,239,1) 0%, rgb(180, 98, 235) 35%, rgba(21,178,237,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
   
  }
  .bottomnavIcon{
    fill: rgb(180, 98, 235);
  }

  .bottomnavbar__link span {
    display: block;
    margin-top: 5px;
  }
  @media(max-width:768px){
    .BottomNav{
      display: block;
    }
  }