.page-not-found{
  font-family: 'Arvo', serif;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background:white;
}
.row{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.row img{
  width: 100%;
}
.page_404 {
  background: #fff;
}
 .text-center{
  text-align: center;
  font-size: 4rem;
  margin: 0;
 }


.contant_box_404 {
}