.canvas-container1{
    position: relative;
    width: 800px;
    height: 600px; 
    background: #222;

}

/* @media(min-width: 1024px){
  .canvas-container1{
    width: 100%;
  }
} */

.canvas {
    position: absolute;
    top: 0;
    left: 0;
  
}
.loaders {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(-50%, 50%);
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    /* margin: auto; */
    /* display: block; */
   
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }