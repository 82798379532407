@import '../../Assets/Styles//theme-dark.css';
@import '../../Assets/Styles/theme-light.css';

.feature-section {
  width: 100%;
  padding: 1rem;
  min-height: 100%;
}

.featurs-con {
  z-index: 2;
  border-radius: 5px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid gray;
  background: var(--color-mode-gray-3);
  color: var(--color-mode-text);


}

.featurs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

.featurs-links .title {
  font-size: 18px;
}

.featurs-links {
  text-decoration: none;
  color: var(--color-mode-text);
  padding: .5rem;
  background: none;
}

.featurs-links .active {
  color: var(--color-mode-text);
  background: var(--color-mode-gray-2);
  
}

.featurs-links .active::before,
.featurs-links .active::after {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
  height: 2px;
}

.featurs-links .active::before {
  width: 50%;
}

.featurs-links .active::after {
  width: 50%;
  left: 50%;
}

.featurs-links:active {
  color: var(--color-mode-primary)
}


.camera-list h3 {
  font-size: 18px;
  margin: 1rem;
}

.anprpage {
  padding: 1rem 0;
  border-radius: 0.2rem;
}

.peaple-count {
  margin-top: 1rem;
  padding: 1rem 0;
  border-radius: 0.2rem;
}

.feature-info {
  margin: 1rem;
  font-weight: 600;
  text-decoration: underline;
  color: var(--color-mode-text);
}