
.aboutus__container{
    background-image: url("../Images/AboutUs.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    flex-direction: column;
    display: flex;
}
.aboutus__div1{
  margin: auto;
}

.div1__heading{
   margin-top: 50px;
   font-size: 40px;
   font-weight: 400;  
   text-decoration: underline;
   text-decoration-color:#6600D3;
   text-underline-position: under;
   font-family: 'Alatsi', sans-serif;
   text-align: center;
}
.aboutus__image{
   display: flex;
   align-items: center;
   justify-content: center; 
   max-width: 1200px;
}
.ContentImg{
   width: 40%;
   height: 20%;
   direction: flex;
   border-radius: 1%;
}
.aboutus__content{
   height: 100%;
    width: 60%;
    padding: 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    text-align: justify;
}
.aboutus__p{
   font-family: system-ui;
   font-size: 18px;
   text-wrap:balance;
   line-height: 1.5;
   font-weight: 400;  
   font-family: 'Open Sans', sans-serif;
}
.div1__heading2{
   width: 100%;
   text-align: center;
   padding: 1rem;
   font-size: 40px;
   text-decoration: underline;
   text-decoration-color:#6600D3;
   text-underline-position: under;
   font-family: 'Alatsi', sans-serif;
}
.aboutus__div2 {
   background-image: url("../Images/AboutUs1.png");
   background-repeat: no-repeat;
   background-size: cover; 
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   gap: 2rem;
   width: 100%;
   place-items: center; 
   margin: auto;
   padding: 1rem;
   grid-row: 1rem;
   opacity: 0; 
   visibility: visible; 
   animation: fadeIn 4s forwards;
}
@keyframes fadeIn {
   from {
     opacity: 0; 
   }
   to {
     opacity: 1;
   }
 }

.div2__Content-TeamWork{
   width: 370px;
   height: 370px;
   gap: 0px;
   border-radius: 10px 0px 0px 0px;
   border: 1px 0px 0px 0px;
   opacity: 0px;
   display: flex;
   align-items: center;
   justify-content: center;
}
.aboutus__div3{
    max-width: 100%;
    background: rgb(227,242,246);
    background: linear-gradient(180deg, rgba(227,242,246,1) 4%, rgba(0,212,255,1) 100%);
}


.aboutus__div--container{
    margin: auto;
    max-width: 1200px;
}
.div3__heading{
   font-size: 36px;
   font-weight: 400;
   padding: 3rem;
   text-align: center;
   text-decoration: underline;
   text-decoration-color:#71C6FF;
   text-underline-position: under;
   font-family: 'Alatsi', sans-serif;
}

.div3__iconNames{
    display: flex; 
    align-items: center;
    justify-content: center;
    gap: 10px; 
    width: 100%;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}
.iconNamesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    align-items: center;
    justify-items: center;
    padding: 20px;
  }
  
  .iconItem {
    text-align: center;
    padding: .4rem 0;
  }
  
  .iconImage {
    width: 50px; /* Adjust the size as needed */
    height: 50px;
    object-fit: contain;
}
  
  .iconName {
    font-size: 16px;
    font-weight: 500;
  }
  


@media (max-width: 768px) {
   .aboutus__div1 {
       flex-direction: column;
   }
   .div1__heading{
    margin-top: 50px;
    font-size:22px;
    text-align: center;
 }
   
   .aboutus__image {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 80px;
        flex-direction: column;
        padding: 2rem;
   }

   .ContentImg {
    width: 100%;
   }

   .aboutus__content {
    width: 100%;
    padding: 1rem;
   }

   .aboutus__p {
    text-wrap: balance;
   }

   .div1__heading2 {
       font-size: 22px; 
       padding: 0.5rem; 
   }

   .aboutus__div2 {
       
       padding: 1.6rem 0; 
   }

   .div2__Content-TeamWork {
       width: 100%; 
       height: auto; 
       border-radius: 10px; 
   }



   .div3__heading {
       font-size: 28px; 
       padding: 2rem; 
   }

 

   .div3__iconNames {
       font-size: 18px; 
   }

   .div3__Names {
       width: 60px; 
       height: 50px; 
   }
   .service_card__img{
        width:14rem
   }
}
@media (max-width: 465px) {
    .service_card__img{
        width:8rem
    }

    .div3__Names{
        font-size: 1rem;
    }
    .div3__heading{
        font-size: 1.5rem;
    }
    .div1__heading{
        margin-top: 3.4rem;
     
     }
     .aboutus__p {
        font-size: 1rem;
    }
    
    
    .div3__iconNames{
        flex-direction: column;
    }
  
}
@media (max-width: 900px){
    .aboutus__content{
        padding: 0%;
    }
    .ContentImg {
        width:32%;
        /* height: 20%; */
    }
    .aboutus__p {       
        font-size: 16px;
        text-wrap: balance;
    }
    .aboutus__image{
        gap:2rem
    }
    .div1__heading{
        font-size: 25px;
    }
    .div1__heading2{
        font-size: 25px;
    }
    .aboutus__div1 {
        width: 100%;
        margin: 0;
    }
}
