@import '../../Assets/Styles//theme-dark.css';
@import '../../Assets/Styles/theme-light.css';

.ClientModal {
  border-radius: 10px;
  width: 470px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}

.ClientOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 999;
}




.client-item {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr 1fr; /* Adjust the fraction units to control the width */
  gap: 10px; /* Adds space between columns */
  align-items: center; /* Align items vertically center */
  padding: .5rem 0;
}


.ClientModal .client-item:last-child {
  border-bottom: none;
}

.ClientModal .client-item .feature-name {
  font-weight: bold;
  color: var(--color-mode-text);
}

.ClientModal .client-item .status {
  color: var(--color-mode-text);
}


.featureContainer {
  background: var(--color-mode-gray-3);
  padding: 1rem;
  width: 100%;
  position: relative
}

.ClientModal .featureHeader {
  margin-bottom: 15px;
  display: flex;
  padding: 10px;

  flex-direction: row;
  justify-content: space-between;
  margin-right: 27%;

  font-weight: 1000;
  background: var(--color-mode-gray-3);
  color: var(--color-mode-text);

}

.client-item {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr 1fr; /* Adjust the fraction units to control the width */
  gap: 10px; /* Adds space between columns */
  align-items: center; /* Align items vertically center */
  margin-top: 1rem;
  justify-content: center;
  color: var(--color-mode-text);

  
}
.client-item:hover{
  background: var(--color-mode-gray-2);
}

.togglefeature {
  grid-column: 1; /* First column */
  background: inherit;
  cursor: pointer;
}

.featureName {
  grid-column: 2; /* Second column */
}

.customDatePickerWidth {
  grid-column: 3; /* Third column */
  background: var(--color-mode-gray-3);
  border:1px solid var(--color-mode-text);
  width: 90%;
  padding:0 .5rem; 
  cursor: pointer;
}

.featureSaveButton {
  grid-column: 4; /* Fourth column */
  background: inherit;
  color:inherit;
  border: 1px solid var(--color-mode-text);
  cursor: pointer;
}


.MuiBox-root{
  background: var(--color-mode-gray-3);
}

.MuiTableContainer{
  background: var(--color-mode-gray-3)!important;
}
.MuiTableRow-root{
  background: var(--color-mode-gray-3)!important;
}
.MuiPaper-root{
  background: var(--color-mode-gray-3)!important;
  margin-top: 4rem;
}
.MuiTableCell-root {
  background: var(--color-mode-gray-3)!important;

}
.MuiBox-root{
  background: var(--color-mode-gray-3)!important;
}
.mdclose {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.6rem;
}
.mdClose {
  color: var(--color-mode-text);
}

.mdClose:active {
  scale: 1.5;
}

