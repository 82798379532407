@import '../../Assets/Styles//theme-dark.css';
@import '../../Assets/Styles/theme-light.css';


.dashboard {
  align-items: flex-start;
  justify-content: space-between;
  color: var(--text-color);
  height: 100%;
  /* display: flex; */
  flex-direction: column;
  flex: 1;
}

.dashboard-header {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
}

.dashboard-content {
  display: flex;
  flex: 1;
}

.sub-dashboard {
  padding: 5px;
}

.MainContent {
  flex-basis: 86%;
  background: var(--color-mode-gray-1);
  position: relative;

}

.main-dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
}

.navbar-container {
  width: 250px; 
  transition: width 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.sidebar.active .navbar-container {
  width: 0;
  opacity: 0; 
}
.MainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .navbar-container {
    width: 0; /* Hide sidebar on small screens */
  }

  .sidebar.active .navbar-container {
    width: 250px; /* Show sidebar when active on small screens */
  }

  .MainContent {
    margin-left: 0;
  }

  .sidebar_button_containe{
    display: none;
  }
}

.sidebar_button {
  background: var(--color-mode-gray-1);
}

.navbar-contaner {
  height: 100%;
  flex-basis: 14%;
  box-shadow: 0 1px 8px 1 rgba(255, 255, 255, 0.08);
}

.header-contaner {
  height: 60px;
  color: var(--text-color);
  z-index: 1;
  box-shadow: var(--box-shadow);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

}
.header.sticky {
  position: fixed;
  top: 0;
  right: 0;
  width: 82%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* height: 100%; */
  background-color: var(--color-mode-gray-3);
  box-shadow: 0px 0px .2rem rgba(0, 0, 0, 0.5);
  border-radius: .5rem;
  padding: 0 1rem;

}

.header-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.header-list button {
 background: none;
 color: var(--color-mode-text);
 
}
.hamBurger{
  font-size: 2.2rem;
  background-color: var(--color-mode-gray-3);
}
.header-link {
  padding: 0.5rem 1rem;
  border-radius: 0.2rem;
  font-size: 16px;
  text-decoration: none;
  color: var(--color-mode-text);
}

.header-title {
  color: var(--color-mode-text);
  font-size: 22px;
  font-weight: 600;
  padding: 1px 0.5rem;
}
.header-Subtitle {
  color: var(--color-mode-primary);
  font-size: 24px;
  
}
.logout-icon {
  font-size: 22px;
}

@media (max-width: 1200px) {
  .header.sticky {
    width: 86%;
  } 
}
@media (max-width: 900px) {
  .header.sticky {
    width: 70%;
  } 
}
@media (max-width: 768px) {
  .MainContent {
    flex-basis: 100%;
   
   

  }
  .header.sticky {
    width: 100%;
  }

  .header-contaner {
    padding: 1rem;
    width: 100%;
  }

  .header-title {
    color: var(--color-mode-text);
    font-size: 16px;
    font-weight: 600;
    padding: 1px 0.5rem;
  }

  .header-container {
    height: 60px;
  }
  .navbar-contaner {
    flex-basis: 0%;
  }
}