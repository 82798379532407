
.reports{
 /* display: flex; */
 width: 100%;
}
.device-report{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 1rem;
    width: 100%;
    padding: 3rem 0;
}
.device-report h1 {
  color: var(--color-mode-text);
}
.active_tooltip{
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.row1{
  padding: 1rem 0;  
display: flex;
}
@media (max-width:768px){
  .row1{
   flex-direction: column;
  }
}

.dms_chart{
    width: 100%;
}
.recharts-wrapper{
    width: 100%!important;
}