/* src/Dropdown.css */
.dropdown {
    position: relative;
    display: inline-block;
    width: 80%;
  }
  
  .dropdown-toggle {
    background-color: #f9f9f9;
    color: #333;
    padding: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
    width: 100%;
    text-align: left;
  }
  
  .dropdown-menu {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    z-index: 1;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .dropdown-menu li {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-menu li:hover {
    background-color: #ddd;
  }
  .condinal__title{
    margin-top: 1rem;
  }

.active_button-col{
  background: var(--color-mode-primary);
  color: var(--color-mode-text);
  }