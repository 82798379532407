.notification {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid var(--color-border);
  margin-bottom: 10px;
  background-color: var(--color-mode-gray-1);
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.notification__title{
  padding: 1rem .5rem;
}
.latest{
  background: var(--color-mode-primary)!important;
  font-weight: bold;
  color: var(--color-white);
  padding: 5px 8px;
  border-radius: 2px;

}
.latestCon{
  background: var(--color-mode-gray-2)!important;
}
.notification-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.notification-type {
  font-weight: bold;
  color: var(--color-mode-text);
  background: var(--color-mode-gray-2);
  padding: 5px 8px;
  border-radius: 2px;
 
}

.configuration__status {
  color: var(--color-para);
  margin: 4px 0;
  font-size: 18px;

}

.notification-message {
  font-weight: 600;
  color: var(--color-mode-primary);
  margin: 4px 0;
}

.notification-time {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: var(--color-para);
}

.notification-start {
  display: flex;
  align-items: center;
  gap: 5px;
}

.notification-action {
  display: flex;
  justify-content: flex-end;
}

.notification-button {
  background: var(--color-border);
  border: none;
  color: var(--color-mode-text);
  padding: 6px 12px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s ease;
  border-radius: .2rem;
}

.notification-button:hover {
  background: var(--color-mode-primary);
  color: var(--color-white);
}



.notification-icon {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.icon {
  font-size: 30px; /* Adjust as needed */
  color: var(--color-mode-text);
}

.notification-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
