.skeleton-dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f9f9f9; /* Light gray background */
}

.skeleton-navbar,
.skeleton-sidebar,
.skeleton-main-header,
.skeleton-thumbnail,
.skeleton-card {
  background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.skeleton-navbar {
  height: 60px;
  margin-bottom: 10px;
}

.skeleton-content {
  display: flex;
  flex: 1;
}

.skeleton-sidebar {
  width: 240px;
  margin-right: 10px;
}

.skeleton-main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.skeleton-main-header {
  height: 70px;
  margin-bottom: 10px;
}

.skeleton-main-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 0 10px;
  height: 100%;
  width: 100%;
}

.skeleton-card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  height: 90%; /* Adjust height as needed */
  width: 100%;
}

.skeleton-thumbnail {
  height: 90px; /* Adjust size as needed */
  border-radius: 8px;
}

.skeleton-text {
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
}

.skeleton-text.long {
  width: 60%;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

/* Media Queries for Responsive Design */

@media (max-width: 768px) {
  .skeleton-dashboard{
    padding: 1rem;
 }
  .skeleton-content {
    flex-direction: column;
  }

  .skeleton-sidebar {
    width: 100%;
    height: 60px;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .skeleton-main {
    flex: 1;
    flex-direction: column;
  }

  .skeleton-main-body {
    padding: 10px;
  }

  .skeleton-card {
    height: 500px; /* Adjust height for smaller screens */
  }

  .skeleton-thumbnail {
    height: 70px; /* Adjust size for smaller screens */
  }

  .skeleton-text {
    height: 16px; /* Adjust text height for smaller screens */
  }

  .skeleton-text.long {
    width: 80%; /* Make text width more responsive */
  }
}

@media (max-width: 480px) {
  .skeleton-dashboard{
     padding: 1rem;
  }
  .skeleton-navbar {
    height: 50px;
  }

  .skeleton-sidebar {
    height: 50px;
  }

  .skeleton-main-header {
    height: 60px;
  }

  .skeleton-main-body {
    padding: 8px;
  }

  .skeleton-card {
    height: 400px; /* Adjust height further for very small screens */
    padding: 8px;
  }

  .skeleton-thumbnail {
    height: 60px;
  }

  .skeleton-text {
    height: 14px;
  }

  .skeleton-text.long {
    width: 90%;
  }
}
