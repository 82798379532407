@import '../../Assets/Styles//theme-dark.css';
@import '../../Assets/Styles/theme-light.css';


.profile-con {
    display: flex;
    align-items: center;
    background: var(--color-mode-gray-3);
    color: var(--color-mode-text);
    z-index: 4;
    position: relative;
}

.profile {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    background: var(--color-mode-gray-3);
    color: var(--color-mode-text);

}

.profile-icon {
    font-size: 3rem;

}
.modal__bg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.03);
    z-index: 999;
}
.userinfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start
}

.userName {
    font-size: 1rem;
    font-weight: 600;
    color: var(--color-mode-text);
    text-transform: capitalize;
}

.profile_modal {
    /* background: var(--color-mode-text); */
    position: absolute;
    top: 60px;
    right: 14px;
    padding:.5px;
    background: var(--color-mode-gray-3);
    width: 18rem!important;
    z-index: 4;
    border-radius: 4px;
}
.min-border {
    border-bottom: 1px solid black; 
    width: 100%; 
}

.modal-content {
    padding: .5rem .5rem;
    list-style: none;

}

.modal-content li:hover{
 background: var(--color-mode-gray-2);
}

.modal-content li {
    display: flex;
    align-items: center;
    gap:0 .5rem;
    color: var(--color-mode-text);
    padding: .5rem!important;
    border-radius: .5px;

}
.modal-content li:nth-child(1):hover {
    background: none;
  }
.userEmail{
    font-size: 16px;
}
.account_title{
 margin-top: .5rem;
font-weight: 600;
}
.modal-content button {
    background: transparent;
    color: var(--color-mode-text);
    display: flex;
    align-items: center;
}

@media (max-width: 600px) {

    .userEmail {
        display: none
    }
    
.profile_modal {
    width: 15rem;
    z-index: 4;
}


 

}

.mobile-con {
    position: relative;
}

@media (min-width: 600px) {
   
    .profile_modal {
        width: 10rem;
        z-index: 4;
    }

}
@media (min-width: 760px) {
    .mobile-con {
        display: none;
    }


}

.mobileHeader {
    position: absolute;
    top: 55px;
    right: 0;
    background: var(--color-mode-gray-3);
    padding: 1rem;
    width: 14rem;

}
.mobileHeader-list{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.mobile-header-link{
    color: var(--color-mode-text);
    text-decoration: none;
    text-wrap: balance;
    
}
.mobile-header-link:hover{
    color: var(--color-mode-text);
    text-decoration: none;
    text-wrap: balance;
    background: var(--color-mode-gray-2);
    
}


.toggle {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 0 .5rem;
  }
  .toggle input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
  .toggle label {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
  .toggle label:before {
    content: "";
    width: 66px;
    height: 35px;
    background: #fff;
    border: 2px solid #ddd;
    box-shadow: inset -30px 0 0 #666;
    position: relative;
    display: inline-block;
    border-radius: 20px;
    box-sizing: border-box;
    transition: 0.25s ease-in;
}
  
  .toggle label:after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    left: 10px;
    top: 10px;
    border-radius: 50%;
    background: #fffdfd;
    box-shadow: inset -8px -5px #333;
    transition: 0.2s ease-in-out;
  }
  
  .toggle input[type="checkbox"]:checked + label:before {
    box-shadow: inset 30px 0 0 #f7f7f7f7;
  }
  .toggle input[type="checkbox"]:checked + label:after {
    box-shadow: inset 0 0 0 2px #fff;
    background: gray;
    left: 39px;
    top: 8px;
    border: 2px dashed #fff;
    background: rgb(212, 209, 17)
  }
