.LiveView{
  /* min-height: 100vh; */
 padding: 2rem;
 }
 .liveView-title{
   display: flex;
   align-items: center;
   justify-content: space-between
 }
 .liveView-title h3{
  color: red;
 }
 .Liveview__container{
   background: var(--color-mode-gray-3);
   margin: "1rem";
    width: "100%";
    border-radius: .5rem;
    margin-top: 1rem;
     
 }
 .Liveview__container h3{
  color: var(--color-mode-text);
  padding: 1rem;
     
 }
 
 .livecards {
     display: grid;
     grid-template-columns: repeat(3, 1fr); /* Three equal-width columns */
     gap: 20px; 
     padding: 3rem;
     
   }
   .padding{
     padding: .5rem;
   }
   .padding h3{
     text-transform: capitalize;
   }
   
  
   
   
   .Livecctv{
     animation: pulsate-color 1s infinite;
     font-size: 4rem;
   }
   
   .liveGrid {
     position: absolute;
     top: 1rem;
     right: 10rem;
     padding: 1rem;
   }
   
   .livecards {
     max-width: 1200px;
     margin: 0 auto;
     display: grid;
     grid-gap: 1.5rem;
   
   }
   .livecards-1 {
     display: grid;
     grid-template-columns: repeat(1, 1fr)!important;
   }
   .livecards-2 {
     display: grid;
     grid-template-columns: repeat(2, 1fr)!important;
   }
   .livecards-3 {
     display: grid;
     grid-template-columns: repeat(3, 1fr)!important; 
   }

   .people-title{
     color: var(--color-mode-text);
   }
   .livecards-3 p {
   color: var(--color-mode-text);
  }
  