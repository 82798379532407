@import '../Styles/theme-dark.css';
@import '../Styles/theme-light.css';

.container1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4rem;
  padding: 2rem;
}
.container2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95vh;
 
  padding: 2rem;
}

.button1 {
  border: 1px solid var(--color-mode-text);
  color:var(--color-mode-text);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  background: var(--color-mode-gray-3);
  margin: 0 .5rem;
}


.button1:active {
  color: var(--color-mode-text);
 
}
.notationInputText {
  font-size: 16px;
  border-radius: 4px;
  padding: 10px 20px !important;
  border:1px solid var(--color-mode-text);
  /* background: var(--color-mode-gray-3); */
}

.notationInputText:focus {
  font-size: 16px;
  border-radius: 4px;
  outline: none;
}



.anotationCon {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
}
.anotationCon, label, input {
  color: var(--color-mode-text);
}
.anotation__group{
   display: flex;
    align-items: flex-end;

}
.anotation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.anotation__container {
 max-width: 1170px;
 width: 50%;
 text-align: center;
 background: var(--color-mode-gray-3);
 padding: 2rem;
 margin-top: 2rem;
}

.anotation__input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:1rem;
  border-radius: .2rem;

}
.ann__inputbox{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: .2rem;
}

.anotation__box {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  
  
}
.anotation__box label {
color:var(--color-mode-text);
padding: .5rem 0;

}

.Anotation__modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  overflow: hidden;
  visibility: visible;
  padding: 1rem;
  background-color: var(--color-mode-gray-3);
    border: 1px solid rgba(255, 255, 255, 0.222);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
  border-radius: 0.5rem;
  height: 60%;
  z-index: 1;
}

.anotation__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

}
.anotation__bg{
 color:rgba(187, 16, 16, 0.05)
}
.anotation__close{
 position:absolute;
 top: 5px;
 right: 5px;
 background: none;
 font-size: 2rem;
 color:gray;
}
.anotation__submit{
 padding: .5rem 1rem;
 margin: 1.7rem 0;
 border: 1px solid var(--color-mode-text);
 background: transparent;
 color: var(--color-mode-text);
}
.anotation__submit:active{
  border: 1px solid rgb(20, 184, 166);
  box-shadow: inset 2px 2px 20px #14b8a6, inset 2px 2px 2px rgb(20, 184, 166);

}

.notationInputFile{
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  margin-right: 100px;
  font-size: 16px;
  width: 200px;
  outline: none;
  cursor: pointer;
  color:var(--color-black)
}

@media (max-width: 600px) {
  .anotationCon {
   flex-direction: column;
   align-items: center;
  }
  .container1 {
    padding:0 1rem;
  }
}
.anotation__contaner{
  position: relative;
}

.notationInputError{
  font-size: 16px;
  border-radius: 4px;
  padding: 10px 20px !important;
  border:1px solid red;
}
@media (max-width:768px){
  .anotation__container {
    width: 80%;
   
   }
}
@media (max-width:1170px){
  .anotation__container {
    width: 80%;
   
   }
}