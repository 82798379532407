
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 92vh;
  width: 100%;
  background-image: url('../Images/loginbg.png');
  background-size: 100% 100%;
}

#background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
  overflow: hidden;
  
}

.card-container {
  min-width: 22%;
  height: 76%;
  background-color: #fff;
  /* border: 1px solid rgba(255, 255, 255, 0.222); */
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px .2rem rgba(0, 0, 0, 0.5);


}
@media (max-width:768px){
.card-container {
  width: 80%;
}
}
@media  (max-width:900px){
.card-container {
  width: 65%;
}
}

.containers {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  border-radius: 8px;
  padding:0 20px;
  gap: 1rem;
  width: 80%;
  z-index: 1;
  overflow: hidden;
}
.heading {
  font-size: 38px;
  font-weight: 700;
  text-align: center;
   font-family:   Montserrat,ui-sans-serif,system-ui,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji"!important;
   color: #000000;
  
}



.no-account {
  font-size: 16px;
  font-weight: 400;
  color: #000000;

}




.btn__group {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

 
}

.buttton-btn {
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  padding: .5rem !important;
  color: #fff;
  width: 100%;
  font-weight: 600;
  background:#EE60DB;
  border-radius: .2rem;
  cursor: pointer;
}


.text {
  font-size: 16px;
}
.form__group{
  width: 100%;
  position: relative;
  
}
.questionText{
 color: #000000 !important;
  
}
.form__input {
  font-size: 1rem !important;
  padding: .4rem .5rem !important;
  font-family: inherit !important;
  border-radius: .2rem  !important;
  background-color: #fff !important; /* Background color */
  box-shadow:none;
  width: 100% !important;
  color: #000000!important;
  border:1px solid #000000;
}

.form__input:focus {
  outline: none;
  box-shadow: 0 0rem .5rem rgba(0, 0, 0, 0.5);
}
.form__input:hover {
  outline: none;
 
}



.form__input::-webkit-input-placeholder {
  color: #999;
}

.form__label {
  font-size: 1rem;
  font-weight: 600;
  padding: .2rem ;
  display: block;
  transition: all .3s;
  color:#000000
}

.form__input:placeholder-shown+.form__label {
  opacity: 0;
  visibility: hidden;
}
.show__password{
  position: absolute;
  top:0;
  right: 0;
  height: 70%;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;

}
.hideIcon{
  font-size: large;
}
.LoginSingupText{
  color: var(--color-mode-primary)
}
.error{
  color: red;
}
.labelErrorGroup{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.toggle-password-btn{
 position: absolute;
 top: 50%;
 right:2%;
 background: transparent;
 font-size: 1.5rem;
 color: #000000;
 cursor: pointer;
}
@media (max-width:468px) {
  .card-container {
    width: 100%;
    height: 100%;
   
  }
  .heading {
    font-size: 36px;
    font-weight: 900;
    margin-bottom: 3rem;
    
  }

  
}
