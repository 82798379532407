@import '../../Assets/Styles//theme-dark.css';
@import '../../Assets/Styles/theme-light.css';
  .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    background: var(--color-mode-gray-3)!important;
    
}
.smsAlert__container{
 height: 95vh;
 padding: 2rem;
}
.smsAlert__container h2{
font-size: 16px;
font-weight: 600;
color: var(--color-mode-text)!important;
}
.add_alertbtn{
 color: var(--color-mode-text);
 padding: .5rem 1rem;
 border-radius: .2rem;
 box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  background-color: var(--color-mode-gray-3);
  border: 1px solid var(--color-border);
}

.sms__tblecell{
    color: var(--color-mode-text)!important;
    font-weight: 600!important;
}

.person_not_detected {
    text-align: center;
}

.data-cell{
    color: var(--color-mode-gray-5)!important;
    text-align: center;
}
.add_alert-delete{
    padding: .5rem 1rem;
    border-radius: .2rem;
    color: red;
   
}
.add_alert-delete:hover{
    background: red;
    color: aliceblue;
}

@media (max-width:768px){
    .smsAlert__container {
        padding: 1rem;
    }
}
.AlertModal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 22.5%;
    overflow: hidden;
    visibility: visible;
  
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
    border-radius: 0.5rem;
    z-index: -999;
  
}    