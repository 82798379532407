.homenavbar {
    background: #ffffff;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 9;
    width: 100%;
    box-shadow: 2px 2px 1rem rgba(0, 0, 0, 0.5);
    padding: 2.6rem;
    position: sticky;
    top: 0;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Optional: style for when the navbar becomes sticky */
.homenavbar.li {
    background: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.homenavbar__link_con {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.homenavbar__link {
    color: black;
    font-size: 16px;
    text-decoration: none;
}

.homenavbar__link:hover {
    background: linear-gradient(90deg, rgba(250,57,239,1) 0%, rgb(180, 98, 235) 35%, rgba(21,178,237,1) 100%);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}

.loginbtn {
   display: none !important;
}

.active{
}



.loginbtn:active {
    transform: scale(0.75);
}

.logo {
    width: 4rem;
}

/* Active link style */

.slide-container {
    width: 100%;
    position: relative;
}

.slick-dots {
    bottom: 20px !important;
}
.mobile-menu-icon{
    display: none;
}
.flip-card__btn {
    margin: 20px 0 20px 0;
    width: 120px;
    height: 40px;
    border-radius: 5px;
    border: 2px solid #323232;
    background-color: #fff;
    box-shadow: 4px 4px var#323232;
    font-size: 17px;
    font-weight: 600;
    color: var(--font-color);
    cursor: pointer;
  } 

  .cta {
    background: linear-gradient(90deg, rgba(250,57,239,1) 0%, rgb(180, 98, 235) 35%, rgba(21,178,237,1) 100%);
    border: none;
    border-radius: 50px;
    color: white;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 10px rgba(250, 57, 239, 0.6), 0 2px 6px rgba(21, 178, 237, 0.6);
  }
  
  .cta .span {
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .cta .second {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .cta .second svg {
    transition: transform 0.3s ease;
  }
  
  .cta:hover {
    background: linear-gradient(90deg, rgba(21,178,237,1) 0%, rgb(180, 98, 235) 35%, rgba(250,57,239,1) 100%);
    box-shadow: 0 6px 14px rgba(250, 57, 239, 0.8), 0 3px 8px rgba(21, 178, 237, 0.8);
  }
  
  .cta:hover .second svg {
    transform: translateX(5px); /* Slide arrow on hover */
  }
  
  .one, .two, .three {
    fill: #FFFFFF; /* Arrow color */
  }
  .navlogin{
    display: none
}

/* Mobile Navbar Styles */
@media (max-width: 924px) {
    .mobile-menu-icon{
       display: block
    }
    .homenavbar__link_con {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;
        display: none;
        transition: all 0.3s ease;
        color:#3333;
        background: #ffff;
    }

    .homenavbar__link_con.active {
        display: flex;
    }

    .homenavbar__link {
        padding: 0.5rem 0;
        width: 100%;
        background: linear-gradient(90deg, rgb(20, 19, 20) 0%, rgb(180, 98, 235) 35%, rgba(21,178,237,1) 100%);
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
    }
    .homenavbar__link:hover {
        background: linear-gradient(90deg, rgba(250,57,239,1) 0%, rgb(180, 98, 235) 35%, rgba(21,178,237,1) 100%);
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
    }

    .loginbtn {
        margin-top: 1rem;
        width: 100%;
    }

    .mobile-menu-icon {
        display: block;
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        z-index: 10;
       
    }
    .homelink{
    color: black!important;
    }
    
  .cta {
   margin-left: 2rem;
  }
  .homenavbar__right{
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;

  }
 
}

@media (max-width: 768px) {
    .homenavbar {
        padding: 1rem;
    }
    .navlogin{
        display: block
    }

    .logo {
        width: 3rem;
    }
}

@media (max-width: 768px) {
    .cta {
       display: none;
      }
}

  