/* InstructionsModal.css */
@import '../Styles/theme-dark.css';
@import '../Styles/theme-light.css';

/* General Modal Styles */
.instruction-modal {
    width: 100%;
    position: relative;
}

.ReactModal__Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    

}

.ReactModal__Overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.03);
    z-index: 999;

} 
/* .ReactModal__Content{
    padding: 0 5rem;
} */


.ReactModal__Content h2 {
    margin-top: 0;
    font-size: 24px;
    color: var(--color-heading);
    padding: 1rem 0;
}

.ReactModal__Content p {
    font-size: 16px;
    color: var(--color-para);
    line-height: 1.6;
    text-wrap:balance;
}

.ReactModal__Content ol {
    margin-left: 20px;
    padding-left: 0;
}

.ReactModal__Content li {
    /* margin-bottom: 10px; */
    font-size: 16px;
    color: var(--color-para);
    padding: .5rem 0;
}

.ReactModal__Content strong {
    color:var(--color-mode-text);
    background: var(--color-mode-gray-2);
    padding: .3rem;
}

.ReactModal__Content .close-butt{
    position: absolute;
    color: var(--color-mode-text);
    right: 0;
    top: 2;
    font-size: 4rem;
    padding: .5rem;
    border-radius: .5rem;
    background: none;
   
}

.close-butt:hover {
    background-color: var(--color-mode-text);
    color: var(--color-heading)
}
.close-butt{
    font-size: 16px;
  }

@media (max-width:768px){
    .ReactModal__Content h2 {
        font-size: 18px;
        color: var(--color-mode-text);
    }
    .ReactModal__Content{
        font-size: 14px;
    }
    .ReactModal__Content .close-butt{
        top: 0;
        font-size: 1rem;
       
    }
  
}
