@import '../src/Assets/Styles/theme-dark.css';
@import '../src/Assets/Styles/theme-light.css';

.light {
   /* Light Mode Colors */
  --color-mode-primary: #7B57E0;
   --color-mode-secondary: #FFFFFF;
   --color-mode-background: #F7f7f7f7;
   --color-mode-gray-1: #f2f2f2;
   --color-mode-gray-2: #DCE3EB;
   --color-mode-gray-3: #fff;
   --color-mode-gray-4: #212427;
   --color-mode-gray-5: #576776;
   --color-mode-text: #000000;
  --color-mode-text2: #007BFF;
   --text-color:#2B2E48;
   --active-color:#dcd8eb;
   --table-bg:#0000;
   --color-black:#000000;
   --color-white:#ffffff;
   --color-border:#ccc;
   --color-para: #333;
   --color-heading:#555;
   --color-bg-home-horizontal:linear-gradient(90deg, rgba(250,57,239,1) 0%, rgb(180, 98, 235) 35%, rgba(21,178,237,1) 100%);
   --color-bg-home-vertical:linear-gradient(180deg, rgba(250,57,239,1) 0%, rgb(180, 98, 235) 35%, rgba(21,178,237,1) 100%);
   --color-bg-home-cross:linear-gradient(to bottom right, rgba(250,57,239,1) 0%, rgb(180, 98, 235) 35%, rgba(21,178,237,1) 100%);
 }

 
 .dark {
  --color-mode-primary: #80B93B;;
  --color-mode-secondary: #191023;
  --color-mode-background: #191023;
  --color-mode-gray-1: #2B2C30;
  --color-mode-gray-2: #37393d;
  --color-mode-gray-3: #2d2f31;
  --color-mode-gray-4: #aaa;
  --color-mode-gray-5: #DCDCDD;
  --color-mode-text: #FFFFFF;
  --color-mode-text2: #f9f9f9;
  --text-color:#000000;
  --active-color:#1c1d1f;
  --table-bg:black;
  --color-black:#000000;
  --color-white:#ffffff;
  --color-border:#646363;
  --color-heading:#f6f6f6;
  --color-para:#ddd;
  --color-bg-home-horizontal:linear-gradient(90deg, rgba(250,57,239,1) 0%, rgb(180, 98, 235) 35%, rgba(21,178,237,1) 100%);
  --color-bg-home-vertical:linear-gradient(180deg, rgba(250,57,239,1) 0%, rgb(180, 98, 235) 35%, rgba(21,178,237,1) 100%);
  --color-bg-home-cross:linear-gradient(to bottom right, rgba(250,57,239,1) 0%, rgb(180, 98, 235) 35%, rgba(21,178,237,1) 100%);
 }

*,
*::before,
*::after{
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  box-sizing:border-box;
}
body{
  background: var(--color-mode-gray-1);
  font-family: 'Open Sans', sans-serif;
  /* font-family: 'Ubuntu', 'Arial', sans-serif; */
  /* font-family: 'Georgia', 'Times New Roman', Times, serif; */
  /* font-family: 'Roboto', 'Open Sans', 'Segoe UI', sans-serif; */
  /* font-family: 'Fira Code', 'JetBrains Mono', Consolas, 'Courier New', monospace; */
  font-size: 18px;
    line-height: 1.8;
}
body::-webkit-scrollbar {
  width: 0px; /* Safari and Chrome */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.App {
  width: 100%;
  color: var(--text-color);
 background: var(--color-mode-gray-1);
 min-height: 95vh;

}
h1 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700; /* Bold */
}

p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400; /* Normal */
}





