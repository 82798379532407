
@import '../../Assets/Styles//theme-dark.css';
@import '../../Assets/Styles/theme-light.css';

.GuideCards{
  display: flex;
  align-items: flex-start;
  justify-content: center;
 }
.guide-card {
    border: 1px solid var(--color-border);
    border-radius: 5px;
    padding: 24px;
    margin: 16px;
    max-width: 600px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    background-color: var(--color-mode-gray-3);
  }
  .guide-card:nth-last-of-type(1){
   flex: 1;
  }
  .guide-card:nth-last-of-type(2){
    flex: 1;
  }
  .guide-card:nth-last-of-type(3){
    flex: 1;
  }
  .guide-card h2 {
    margin-top: 0;
    color: var(--color-heading)
  }
  
  .guide-card h3 {
    margin-bottom: 8px;
    color: var(--color-mode-text2);
  }
  
  .guide-card p {
    margin: 8px 0;
    color: var(--color-para)
  }
  .guide-card li{
   margin-bottom: 3px;
    color: var(--color-para);
    line-height: 1.4;
    
  }
  .guide-card li strong{
     background: var(--color-mode-gray-2);
     padding: 2px;
   }
   
   @media (max-width: 565px) {
    .GuideCards{
      flex-direction: column;
    }
   }