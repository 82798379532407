.tab-bar {
  display: none;
}

.tab-button {
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  color: var(--color-mode-text);
  transition: color 0.3s ease;
}

.tab-button.active {
  font-weight: bold;
  color: var(--color-mode-primary);
  border-bottom: 2px solid var(--color-mode-primary);
}

.tab-button:hover {
  color: var(--color-mode-primary);
}

.tab-content {
  padding: 20px;
  border: 1px solid var(--color-mode-text);
  border-top: none;
  background-color: var(--color-mode-gray-3);
}

@media(max-width:768px) {
  .tab-bar {
    display: block;
    display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 0;
  background-color: var(--color-mode-gray-3);
  background: var(--color-mode-gray-3);
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  }

}