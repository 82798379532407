
:root {
  /* Light Mode Colors */
  --color-mode-primary: #7B57E0;
   --color-mode-secondary: #FFFFFF;
   --color-mode-background: #F7f7f7f7;
   --color-mode-gray-1: #f2f2f2;
   --color-mode-gray-2: #DCE3EB;
   --color-mode-gray-3: #fff;
   --color-mode-gray-4: #212427;
   --color-mode-gray-5: #576776;
   --color-mode-text: #000000;
  --color-mode-text2: #007BFF;
   --text-color:#2B2E48;
   --active-color:#dcd8eb;
   --table-bg:#0000;
   --color-black:#000000;
   --color-white:#ffffff;
   --color-border:#ccc;
   --color-para: #333;
   --color-heading:#555;
   --color-bg-home-horizontal:linear-gradient(90deg, rgba(250,57,239,1) 0%, rgb(180, 98, 235) 35%, rgba(21,178,237,1) 100%);
   --color-bg-home-vertical:linear-gradient(180deg, rgba(250,57,239,1) 0%, rgb(180, 98, 235) 35%, rgba(21,178,237,1) 100%);
   --color-bg-home-cross:linear-gradient(to bottom right, rgba(250,57,239,1) 0%, rgb(180, 98, 235) 35%, rgba(21,178,237,1) 100%);
}






