@import '../Styles/theme-dark.css';
@import '../Styles/theme-light.css';

/* Default styles for all screen sizes */
.react__table-container {
max-width: 1400px;
  width: 100%;  
  margin: 0 auto;
 
}

.css-1tbggly {
  z-index: none !important;
}

.css-11786dc-MuiPaper-root {
  z-index: none;
}

/* Styles for screens smaller than or equal to 600px */
.action__modal{
  z-index: 5;
}
.actionModel{
 top: 0;
 right:0;
 height: 10rem;
 width: 10rem;
}


.css-680k2-MuiTableRow-root td {
  color: #ccc !important;

}

.css-1atu56z-MuiPaper-root {
  width: 100% !important;
}

.css-fsbybz-MuiTableRow-root {
  /* background: var(--color-mode-secondary) !important;; */
  width: 100%;
}

.react_table {
  width: 100%;

}


.cell {
  padding: 1rem !important;
  font-size: 1rem !important;
  /* height: 70px !important; */
}

.table-body {
  border: 1px solid red !important;
  margin: 0 !important;
}

.header-row {
  margin: 0 !important;
}

.table-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  width: 100%;
}

.table-btn h1 {
  color: var(--color-mode-text);
  padding: 0 1rem;
}

.table-btn-con h1 {
  font-size: 22px;
  font-weight: 600;
  color: var(--color-mode-text);

}

.table-add-btn {
  color: var(--color-mode-text);
  border: 1px solid var(--color-mode-text);
  border-radius: 5px;
  font-weight: 600;
  padding: .7rem 1rem;
  background: var(--color-mode-gray-3);
}

.table-add-btn:hover {
  color:var(--color-mode-gray-3);
  background:var(--color-mode-text);
  border: 1px solid var(--color-mode-text);


}

.toggle-btn {
  font-size: 20px;
}

 .Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 22%;
  overflow: hidden;
  visibility: visible;

  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  z-index: -999;


}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.03);
  z-index: 999;
  
}


table {
  border: 1px solid lightgray;
}

tbody {
  border-bottom: 1px solid lightgray;
}

th {
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding: 2px 4px;
}

tfoot {
  color: gray;
}

tfoot th {
  font-weight: normal;
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: var(--color-mode-secondary);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: blue;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover>.resizer {
    opacity: 1;
  }
}


.alertAddform {
  padding: 1rem;
}

.alertAddform .inputGroup {
  width: 100%;
}

@media(min-width: 968px) {
  .Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    height: 650px;
    min-width: 24%;        /* Minimum width */
    max-width: 30%;
    overflow: hidden;          /* Hide scrollbars */
    overflow-y: scroll;        /* Enable vertical scrolling */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;     /* Firefox */
  
  }
}

@media(max-width: 968px) {
  .Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 48%;
    height: 650px;
    overflow-y: scroll;
  
  }
}
@media(max-width: 768px) {
  .Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
  
  }
}
@media(max-width: 400px) {
  .Modal {
    width: 98%;
  
  }
 
}
@media(max-width: 600px) {
  .react__table-container {
    padding: 0 10px;
  }

  .Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 78%;
    height: 650px;
    overflow-y: scroll;
  
  }
  .css-11786dc-MuiPaper-root {
    width: 100% !important;
  }

}