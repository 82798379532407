
.root {
  --color-mode-primary: #80B93B;;
  --color-mode-secondary: #191023;
  --color-mode-background: #191023;
  --color-mode-gray-1: #2B2C30;
  --color-mode-gray-2: #37393d;
  --color-mode-gray-3: #2d2f31;
  --color-mode-gray-4: #aaa;
  --color-mode-gray-5: #DCDCDD;
  --color-mode-text: #FFFFFF;
  --color-mode-text2: #f9f9f9;
  --text-color:#000000;
  --active-color:#1c1d1f;
  --table-bg:black;
  --color-black:#000000;
  --color-white:#ffffff;
  --color-border:#646363;
  --color-heading:#f6f6f6;
  --color-para:#ddd;
  --color-bg-home-horizontal:linear-gradient(90deg, rgba(250,57,239,1) 0%, rgb(180, 98, 235) 35%, rgba(21,178,237,1) 100%);
  --color-bg-home-vertical:linear-gradient(180deg, rgba(250,57,239,1) 0%, rgb(180, 98, 235) 35%, rgba(21,178,237,1) 100%);
  --color-bg-home-cross:linear-gradient(to bottom right, rgba(250,57,239,1) 0%, rgb(180, 98, 235) 35%, rgba(21,178,237,1) 100%);
}