@import '../../Assets/Styles//theme-dark.css';
@import '../../Assets/Styles/theme-light.css';


.navbar {
    width: 100%;
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 100dvh;
    padding: 1rem .5rem;
    align-self: flex-start;
    background-color:var(--color-mode-gray-3) ;
    border: 1px solid var(--color-border);

}


.nav__Icon{
    color: var(--color-mode-primary);
    font-size: 1.5rem;
}


.link-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 2rem;
}


.navLink {
    padding: .5rem .5rem;
    text-decoration: none;
    border-radius: .2rem;
    color: var(--color-mode-text);
    width: 100%;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
}

.navlink span {
position: absolute;
top: 50%;
right: 10px;
background: #000;
}
.navLink.active {
    color: var(--color-mode-text);
    background-color: var(--color-mode-gray-2)
}

.navLink:hover {
    background-color: var(--color-mode-gray-2)
}

.navLink-reports {
    padding: .7rem .8rem;
    text-decoration: none;
    border-radius: .2rem;
    color: var(--color-mode-text);
    width: 100%;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: .5rem;
}

.navLink-reports.active {
    color: var(--color-mode-text);
    background-color: var(--color-mode-gray-2)
}

.navLink-reports:hover {
    background-color: var(--color-mode-gray-2)
}



@media(max-width: 760px) {
    .navbar {
        display: none;
    }

}
@media(min-width: 600px) {
    .navbar.sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 250px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        z-index: 1000;
      }
}
@media (max-width: 900px){
   
    .link-list{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: .5rem;
    }
    .link-list .button{
      background-color: none;
    }
    .navbar {
        
        justify-content: space-between;
        height: 100dvh;
        padding: 1rem .5rem;
        align-self: flex-start;
        background-color:var(--color-mode-gray-3) ;
        /* display: none; */
    
    }
  }