@import '../../Assets/Styles//theme-dark.css';
@import '../../Assets/Styles/theme-light.css';

.multiple__stream{
  width: 100%;
  min-height: 100%;
 }
 .multiple__streamp{
  width: 50%;
  height:40%;
  background:black
 }
 .streamCard{
     display: flex;
     align-items: center;
     gap: 1rem;
 }
 
 .camera_design {
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
   }
   
   .video-container {
     position: relative;
     height: 100%;
     width: 100%;
     background:#222;
     border-radius: 5px;

   }
   
   canvas {
     width: 100%;
     height: 100%;
     border-radius: 5px;
   }
   
   .controls {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     display: flex;
     justify-content: center;
     padding: 1rem;
   }
   
   .live__btn {
     margin: 0 0.5rem;
     padding: 0.5rem 1rem;
     font-size: 1rem;
     color: var(--color-mode-text);
     border: none;
     border-radius: 0.25rem;
     cursor: pointer;
     background: none;
 }
   .live__btn:hover {
     background: none;
 }
   .live__btn .play__pause {
     color: white;
     font-size: 2.2rem;
 
 }
 .full__screen-btn{
     position: absolute;
     bottom: 20px;
     right: 20px;
     font-size: 20px;
     background: transparent;
     color: white;
 }
 .video__feed{
     position: absolute;
     width: 100%;
     height: 100%;
     top: 0;
     left: 0;
     z-index: 1;
     background: #000;
 }
 
   
   
 