.text{
    /* background: rgba(255, 255, 255, 0.1);  */
    background:white;
    border-radius: 10px; /* Rounded corners */
    backdrop-filter: blur(10px); /* Glass-like blur effect */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
    padding: 20px; /* Space inside the card */
    width: 80%; /* Maximum width of the card */
    color: #333; /* Text color */
    border: 1px solid transparent; 
  }
.banner_pink {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    padding: 1.6em;
    font-family: Georgia, 'Times New Roman', Times, serif;
    
}

.banner_blue {
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #FFFFFF 0%, #71C6FF 100%);
    width: 100%;
}
.feature__container{
    max-width: 1200px;
}
.feature-sections{
    background: linear-gradient(180deg, #F762D8 0%, #71C6FF 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
}
.featureCard__container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
}

.contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    padding: 1.6rem;


}
.contents__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 68%;
    padding: 1.6rem;
   display: none;

}


.textr {
    padding: 1rem;
    height: 70%;
    border-radius: 15px;
}

.textl {
    padding: 1rem;
    height: 70%;
    border-radius: 15px;
}
.text p {
    text-align: justify;
    padding-top: 1rem;
   
    font-size: 1.3rem;

}

.text h3 {
   
    font-weight: bold;
    font-size: 1.5rem;
    
}

.imagesl {
    width: 40%;
   
}

/* .scanner{
    width: 30%;
} */
.imagesr {
    height: 13rem;
    width: 13%;
 
}
.imagesrM{
height: 10rem;
width: 20%;
}

.imagesr__img{
  width: 100%;
  height:100%;
  object-fit: contain;
}



.textl:hover{
    border: 1px solid gray;
}

.textr:hover{
    background: transparent;
}

/* Styles for screens up to 1440px */
@media (max-width: 1440px) {
    .contents {
        /* Your styles here */
    }
}

/* Styles for screens up to 1220px */
@media (max-width: 1220px) {
    .contents {
        width: 92%;
    }
    .text p {
        font-size: 1.2rem;
        font-weight: 400;
    }
}

/* Styles for screens up to 1024px */
@media (max-width: 1024px) {
    .contents {
        width: 92%;
    }
    .text p {
        font-size: 1rem;
        font-weight: 400;
    }
}

/* Styles for screens up to 834px */
@media (max-width: 834px) {
    .contents {
        width: 92%;
    }
    .text p {
        font-size: 1rem;
        font-weight: 400;
    }
}

/* Styles for screens up to 768px */
@media (max-width: 768px) {
    .contents {
        width: 100%;
        display: none;
        
    }
    .contents__box {
       display: block;
    
    }
    .text p {
        font-size: .8rem;
        font-weight: 400;
    }
    .text h3 {
        font-size: 1.2rem;
    }
    .textl {
        padding: 1rem;
        width: 100%;
        border-radius: 15px;
    }
    .contents__box{
        width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .imagesrM {
        width: 70%;
        height: 10rem;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .imagesr__img{
        width: 50%;
        object-fit: con;
      }
}
