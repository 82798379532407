.skeleton-titles{
 width: 80%;
 background: gray;
 height: 30px;
 margin-top: 1.5rem;
 border-radius: 5px;
}

.skeleton-group-selection{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1px solid gray;
    margin-top: 1rem;
    gap: 3rem;

}
.skeleton-squire-box{
    width: 150px;
    background-color: gray;
    height: 200px;
    border-radius: 5px;
}

.skeleton-user-alert-selection{
  border:1px solid gray;
  height: 100px;
  width: 100%;
  margin-top: 1rem;
  border-radius: 5px;
  padding: 1rem;
  text-align: center;


}
.skeleton-Container{
    border: 1px solid gray;
    padding: 1.5rem;
    border-radius: 5px;
    margin-top: 1rem;
}
.smsModal_skeleton{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.skeleton-submit-button-con{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
}
.skeleton-submit-button{
    width: 150px;
    height: 40px;
    background-color: gray;
    border-radius: 5px;
}