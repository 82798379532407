.configStream .skeleton-title {
    font-size: 24px;
    /* Add more styling to match the actual title */
  }
  

  
  .skeleton-loaders {
    /* background-color: #ccc; */
    height: 20px;
    margin-bottom: 10px;
    /* Adjust size and color to match the loaders in the actual component */
  }
  
  .skeleton-canvas {
    border: 1px solid #ccc;
    height: 300px;
    margin-top: 10px;
    /* Adjust dimensions and border to match the canvases in the actual component */
  }
  
  .button-container {
    display: flex;
    gap: 10px;
    /* Style to match the button container in the actual component */
  }
  
  .skeleton-button {
    padding: 8px 12px;
    border-radius: 4px;
    background-color: #f0f0f0;
    /* Style to match the buttons in the actual component */
  }
  