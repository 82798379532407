/* src/customStyles.css */
.small-swal-popup {
    width: 400px !important;
    height: 300px !important;
    font-size: 0.9rem !important;
    border-radius: 10px !important; /* Example: rounded corners */
    padding: 10px !important; /* Example: custom padding */
  }
  
  .small-swal-title {
    color: #1e90ff !important; /* Example: custom title color */
    font-size: 1.2rem !important; /* Example: custom title size */
  }
  
  .small-swal-content {
    color: #4b4b4b !important; /* Example: custom content color */
    font-size: 0.9rem !important; /* Example: custom content size */
  }
  .no-overlay .swal2-backdrop-show {
    background: 'red' !important; /* Removes overlay color */
  }