@import '../../Assets/Styles//theme-dark.css';
@import '../../Assets/Styles/theme-light.css';

.cameraContainer {
  text-decoration: none;
}

.cameraVideo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cameraVideo .cctv {
  font-size: 10rem;
  color: var(--color-mode-gray-4)
}

.camera_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: none;
}

.camera_heading .cameraConfig {
  padding:.5rem;
  background: transparent;
  color: var(--color-mode-primary);
  border: 1px solid var(--color-mode-gray-4);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.camera_heading .cameraConfig:hover {
  border: 1px solid var(--color-mode-gray-4)
}

.camera_heading .title {
  font-size: 16px;
}


.container :hover {
  box-shadow: 0px 0px 20px 1px #ffbb763f;
}

.title {
  padding: .5rem;
  text-transform: capitalize;
}

.cameraTitle {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width:468px) {
    .camera_card {
        width: 417px;
        height: 300px;
    }


  .cameraVideo .cctv {
    font-size: 5rem;

  }

  .camera_heading .cameraConfig {
    padding: .7rem 0.4rem;

  }
  .camera_heading .title {
    font-size: 16px;
}

}
.cards{
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal-width columns */
  gap: 20px; 
  padding: 2rem;
  width: 100%;
  max-width: 1200px;
 }
.camera_card {
  width: 100%;
  max-width: 455px;
  height: 280px;
  position: relative;
  display: grid;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  color: var(--color-mode-text);
  font-family: sans-serif;
  background: var(--color-mode-gray-3);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-radius: 5px;
  border: 1px solid var(--color-border);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
}

/* For devices with a max-width of 1200px */
@media (max-width: 1200px) {
  .camera_card {
    width: 330px;
    height: 200px;
    
  }
  .cameraVideo .cctv {
    font-size: 5rem;

  }
  .cards{
    grid-template-columns: repeat(2, 1fr); /* Three equal-width columns */
  
   }
   .cameraConfig {
    padding: .5rem .2rem;
  }
}

/* For devices with a max-width of 900px */
@media (max-width: 900px) {
  .camera_card {
    width: 100%;
    height: auto;
    
  }
  .cameraVideo .cctv {
    font-size: 4rem;

  }
}

/* For devices with a max-width of 768px */
@media (max-width: 768px) {
    .camera_card {
      width: 320px;
      height: 200px;
    }
    .cards{
      padding: 1rem;
        grid-template-columns: repeat(2, 1fr);
    }
}

/* For devices with a max-width of 468px */
@media (max-width: 468px) {
  .camera_card {
    width: 100%;
    height: 200px;
   
  }
  .cards{
    grid-template-columns: repeat(1, 1fr);
  }
}