
.detected-view-card {
    padding: 1rem;
    margin: 1rem auto;
    color:var(--color-mode-text);
    border: 1px solid var(--color-border);
    border-radius: 5px;
    max-width: 600px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    background-color: var(--color-mode-gray-3);


  }
  
  .notification-header {
    display: flex;
    align-items: center;
  }
  
  .detected-image {
    width: 150px;
    height: 100px;
    border-radius: 8px;
    margin-right: 1rem;
  }
  
  .notification-content {
    flex: 1;
  }
  
  .notification-body {
    margin-bottom: 1rem;
  }
  
  .notification-type {
    font-weight: bold;
    font-size: 1.1rem;
  }
  
  .notification-feature {
    font-size: 0.9rem;
    color: var(--color-mode-text);
  }
  
  .configuration-status {
    color: var(--color-mode-text);
    font-size: 0.85rem;
  }
  
  .notification-message {
    font-size: 0.85rem;
    color:var(--color-mode-text);
  }
  
  .notification-time {
    display: flex;
    justify-content: space-between;
    color:var(--color-mode-text);
    font-size: 0.85rem;
  }
  
  .notification-start {
    display: flex;
    align-items: center;
  }
  
  .time-icon {
    margin-right: 0.3rem;
  }
  
  .no-alerts {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-radius: 8px;
    color: var(--color-mode-text);
  }
  .notifiction__page h1 {
    color: var(--color-mode-text);
  }
 
  .MuiSvgIcon-fontSizeMedium, .MuiSelect-standard,.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows , .MuiIconButton-root {
    color: var(--color-mode-text) !important;
  }