
.modelTraining-card {
  max-height: 300px; /* Set a max height for the entire card */
  min-width: 400px;
  display: flex;
  flex-direction: column;
  /* background-color: var(--color-mode-gray-3); */
  border: 1px solid var(--color-mode-primary);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9999;
  padding: 10px;
}

.scrollable-content {
  max-height: 200px; /* Set a height or max-height to make it scrollable */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Hide horizontal scrolling */
  padding: 5px;
}
/* Custom scrollbar styles for webkit browsers */
.scrollable-content::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.scrollable-content::-webkit-scrollbar-track {
  background: var(--color-mode-primary); /* Background of the track */
  border-radius: 10px; /* Rounded corners for the track */
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: var(--color-mode-primary); /* Color of the scrollbar handle */
  border-radius: 10px; /* Rounded corners for the handle */
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: var(--color-mode-primary); /* Color of the handle on hover */
}

/* For Firefox */
.scrollable-content {
  scrollbar-width: thin; /* Options: auto | thin | none */
  scrollbar-color:var(--color-mode-primary);  /* thumb color and track color */
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-mode-text);
  padding: 10px;
}

.card-body {
  margin-top: 20px;
  color: var(--color-mode-text);
  border: 1px solid red;
  background: var(--color-mode-gray-3);
}

.training-animation {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #ccc;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.card-footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.card-footer button {
  color:var(--color-mode-text);
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: none;
}
.card-footer button:hover {
  background-color:var(--active-color);
}

.close-icon {
  cursor: pointer;
  font-size: 20px;
}
.resize-icon{
  position: absolute;
  bottom:10px;
  right:10px;
  cursor: nwse-resize;
}
.resize-handle{
  position: absolute;
  bottom: 5px;
  right: 5px;
  width:100%;
  height: 20px;
  }
 
